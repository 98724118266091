<template>
    <div class="Ad-container">
       <a v-for="(ad ,index) in adList" :key="'ad'+index" :title="ad.Title" target="_blank" :href="ad.NavUrl"  > 
           <img :src="getImgUrl(ad.ImgUrl)" />
       </a>
    </div>
</template>

<script> 

import appSettings from '../util/settings';
import axios from 'axios';

export default {
  name: 'RenderAd',
  data () {
    return {
      adList:null
    }
  },
  methods:{
      getImgUrl(imgurl){ 
        return  require('../../public/img/advertisement/' + imgurl);
      }
  },
  mounted () {
    var self = this;
    axios
      .get(appSettings.GetAdList)
      .then(response => (this.adList = response.data))
      .catch(function (error) {  
        console.log(error);
      });
    
  }
}
</script>