<template>
    <CDropdown
            inNav
            class="c-header-nav-items"
            placement="bottom-end"
            add-menu-classes="pt-0"
    >
        <template #toggler>
            <CHeaderNavLink>
                <div class="c-avatar">
                    <div class="login-user">
                        <button class="btn login-user-firsword-btn">{{userName}}</button>
                    </div>
                </div>
            </CHeaderNavLink>
        </template>
        <CDropdownItem v-if="!tryFree">
            <CButton @click="myProfile">
                <CIcon name="cil-user"/>
                我的檔案
            </CButton>
        </CDropdownItem>
        <CDropdownDivider v-if="!tryFree"/>
        <CDropdownItem v-if="!tryFree">
            <CButton @click="myAccount">
                <CIcon name="cil-dollar"/>
                我的交易
            </CButton>
        </CDropdownItem>
        <CDropdownDivider v-if="!tryFree"/>
        <CDropdownItem>
            <CButton @click="signOut">
                <CIcon name="cil-lock-locked"/>
                登出
            </CButton>
        </CDropdownItem>
    </CDropdown>
</template>

<script>
    // import * as firebase from "firebase";
    import store from '../util/store.js';

    export default {
        name: 'TheHeaderDropdownAccnt',
        data() {
            return {
                //userName: titleCase(firebase.auth().currentUser.displayName)
                userName: titleCase(store.state.userName),
                tryFree: false
            }
        },
        mounted(){
            //console.dir(store.state.tryFree);
            this.tryFree = store.state.tryFree;
        },
        methods: {
            signOut() {
                // firebase.auth().signOut().then(() => {
                //   this.$router.push({
                //         path:'/login',
                //         name:'Login',
                //         params:{
                //         }
                //     });
                // }).catch(err => console.log(err));
                store.commit("clearUser");
                this.$router.push({
                    path: '/login',
                    name: 'Login',
                    params: {}
                });
            },
            myAccount() {
                this.$router.push({
                    path: '/myAccount',
                    name: 'MyAccount',
                    params: {}
                });
            },
            myProfile() {
                this.$router.push({
                    path: '/MyProfile',
                    name: 'MyProfile',
                    params: {}
                });
            },
            myReports() {
                this.$router.push({
                    path: '/MyReportList',
                    name: 'MyReportList',
                    params: {}
                });
            }
        }
    }

    function titleCase(str) {
        let splitStr = str.toLowerCase().split(' ');
        for (let i = 0; i < splitStr.length; i++) {
            splitStr[i] = splitStr[i].charAt(0).toUpperCase();// + splitStr[i].substring(1);
        }
        // Directly return the joined string
        return splitStr.join('');
    }

</script>

<style scoped>
    .c-icon {
        margin-right: 0.3rem;
    }
</style>