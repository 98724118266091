<template>
  <CHeader fixed with-subheader light style="height:60px">
    <CToggler
      in-header
      class="ml-3 d-lg-none"
      v-c-emit-root-event:toggle-sidebar-mobile
    />
    <CToggler
      in-header
      class="ml-3 d-md-down-none"
      v-c-emit-root-event:toggle-sidebar
    />
    <CHeaderBrand
      class="mx-auto d-lg-none" 
      src="img/brand/logo.svg"
      width="60"
      height="50"
      alt="Getchee Logo"
    />
    <CHeaderNav class="d-md-down-none mr-auto">
      
    </CHeaderNav>
    <CHeaderNav class="mr-4">
      <!--Purchse-->
     	<CButton v-if="$route.path !== '/myAccount'"  @click="BillingPurchse"  style="background:#f6c857;color:#3C4B64">購買</CButton>    
      <TheHeaderDropdownAccnt/>
    </CHeaderNav>
    <CSubheader class="px-3" style="display:none">
      <CBreadcrumbRouter class="border-0" />
    </CSubheader>
  </CHeader>
</template>

<script>
import TheHeaderDropdownAccnt from './TheHeaderDropdownAccnt'

export default {
  name: 'TheHeader',
  components: {
    TheHeaderDropdownAccnt
  },
  methods:{
    BillingPurchse(){   
      let currentRouterName = this.$router.currentRoute.name;
      this.$router.push({
      name: "MyAccount",
      params: { OutSidePurchaseModal: true,TriggerRouterName:currentRouterName  }
      });
    }
  }
}
</script> 