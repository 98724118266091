export default [
  {
    _name: 'CSidebarNav',
    _children: [
      // {
      //   _name: 'CSidebarNavItem',
      //   name: 'Dashboard', 
      //   to:'/dashboard',
      //   icon: 'cil-home', 
      // },     
     
      {
        _name: 'CSidebarNavItem',
        name: '選址',
        to: '/marketReport',
        icon: 'cil-location-pin'
      },
      {
        _name: 'CSidebarNavItem',
        name: '我的報表',
        to: '/MyReportList',
        icon: 'cil-chart-line'
      },
      /*{
        _name: 'CSidebarNavItem',
        name: '交易記錄',
        to: '/MyAccount',
        icon: 'cil-list-rich'
      },*/  
      {
        _name: 'CSidebarNavItem',
        name: '兌換碼登錄',
        to: '/Voucher',
        icon: 'cil-barcode'
      }, 
      {
        _name: 'CSidebarNavItem',
        name: '教學說明',
        to: '/Learn',
        icon: 'cil-video'
      }, 
      {
        _name: 'CSidebarNavItem',
        name: '問題回報',
        to: '/feedback',
        icon: 'cil-comment-bubble'
      },
  
    ]
  }
]