<template>
  <CFooter>
    <div>
      <a href="http://www.getchee.com" target="_blank">Getchee</a>
      <span class="ml-1">&copy; 2020 Getchee.</span>
    </div>
    <div class="ml-auto">
      <span class="mr-1"> </span>
      <a href="" target="_blank"></a>
    </div>
  </CFooter>
</template>

<script>
export default {
  name: 'TheFooter'
}
</script>

<!--
<template>
  <div>
    <div class="row justify-content-center">
      <a href="" target="_blank">Getchee </a>
      <span class="ml-1">&copy; 2020 Getchee.</span>
    </div>
    <div class="ml-auto">
      <span class="mr-1"></span>
      <a href="" target="_blank"></a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TheFooter'
}
</script>
-->
