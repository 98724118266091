<template>
    <CSidebar
            fixed
            :minimize="minimize"
            :show.sync="show"
    >
        <CSidebarBrand
                :imgFull="{ width: 50, height: 50, alt: 'Logo', src: 'img/brand/getcheeLogo.png'}"
                :imgMinimized="{ width: 50, height: 50, alt: 'Logo', src: 'img/brand/getcheeLog-m.svg'}"
                :wrappedInLink="{ href: 'http://zh-tw.getchee.com/', target: '_blank'}"
        />
        <CRenderFunction flat :content-to-render="nav"/>
        <RenderAd/>
        <CSidebarMinimizer
                class="d-md-down-none"
                @click.native="minimize = !minimize"
        />

    </CSidebar>
</template>

<script>
    import nav from './_nav'
    import RenderAd from './RenderAd'
    import store from '../util/store.js';

    export default {
        name: 'TheSidebar',
        data() {
            return {
                minimize: false,
                nav,
                show: 'responsive'
            }
        },
        components: {
            RenderAd
        },
        created() {
            if (store.state.tryFree) {
                this.nav[0]._children.splice(1, 1);
                this.nav[0]._children.splice(1, 1);
            }
        },
        mounted() {

            this.$root.$on('toggle-sidebar', () => {
                const sidebarOpened = this.show === true || this.show === 'responsive'
                this.show = sidebarOpened ? false : 'responsive'
            })
            this.$root.$on('toggle-sidebar-mobile', () => {
                const sidebarClosed = this.show === 'responsive' || this.show === false
                this.show = sidebarClosed ? true : 'responsive'
            })
        }
    }
</script>
